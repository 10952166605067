<template>
  <QuestionList :user="user" :avatarText="avatarText" :searchText="searchText" @error="onError" @success="onSuccess" />
</template>

<script>
import { QuestionList } from 'exam-service-web-lib';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'QuestionPool',
  components: {
    QuestionList,
  },
  computed: {
    ...mapState(['searchText']),
    ...mapState('Auth', ['user']),
    ...mapGetters('Auth', ['avatarText']),
  },
  methods: {
    onError(err) {
      this.$snotify.error(err);
      console.log(err);
    },
    onSuccess(msg) {
      this.$snotify.success(msg);
    },
  },
};
</script>
